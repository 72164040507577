*{
    padding: 0;
    margin: 0;
}

.Consulting-mainSection{
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    max-height: 100%;
    width: 100% !important;
    flex-direction: column;
}

.ConsultingMainH1{
    font-size: 35px;
    font-weight: bold;
    margin-bottom: 20px;
}

.Consulting-mainSection-subDiv{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    max-height: 100%;
    width: 90%;
    box-shadow: 5px 5px 15px rgb(0, 0, 0.9);
    /* border: 1px solid black; */
    margin-bottom: 40px;
}

.ConsultingImageDiv{
    height: 370px;
    width: 400px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.ConsultingImg{
    height: 300px;
    width: 340px;
    border-radius: 5px;
    box-shadow: 0 0 10px red;
}

.ConsultingImg:hover{
    transform: scale(1.1);
    box-shadow: 2px 3px 10px 3px orangered;
    border-top-left-radius: 30%;
    border-bottom-right-radius: 30%;
}

.ConsultingParaDiv{
    width: 100%;
    height: 350px;
    max-height: 100%;
    /* margin-top: 20px; */
}

span{
    font-weight: 900;
    font-size: 20px;
}

.SubDivHeading{
     color: red;
    font-size: 30px;
    margin-bottom: 20px;
}

.ConsultingPara{
    font-size: 20px;
    text-align: justify;
    padding:0px 20px;
}


@media (max-width: 1200px) {
    .Consulting-mainSection{
        flex-direction: column;
    }

    .Consulting-mainSection-subDiv{
        /* flex-direction: column; */
        align-items: center;
        height: 670px;
    }

    .ConsultingImageDiv{
        width: 80%;
        /* margin-bottom: 20px; */
    }

    .ConsultingImg{
        width: 70%;
        height: 300px;
    }

    .ConsultingParaDiv{
        /* margin-top: 30px; */
        width: 650px;
        text-align: justify;
        /* padding: 50px; */
        height: 400px;
        margin-right: 40px;
        margin-left: 40px;
    }

    .ConsultingMainH1, .SubDivHeading{
        font-size: 24px;
    }

    .ConsultingPara{
        font-size: 20px;
    }
}




/* Responsive CSS */
@media (max-width: 768px) {
    .Consulting-mainSection{
        flex-direction: column;
    }

    .Consulting-mainSection-subDiv{
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .ConsultingImageDiv{
        width: 100%;
        /* margin-bottom: 20px; */
    }

    .ConsultingImg{
        width: 300px;
        height: 300px;
    }

    .ConsultingParaDiv{
        /* margin-top: 30px; */
        width: 83%;
        text-align: justify;
        padding:10px 10px;
        /* padding-right: 2px;
        padding-left: 2px; */
        height: auto;
       
    }

    .ConsultingMainH1, .SubDivHeading{
        font-size: 24px;
    }

    .ConsultingPara{
        font-size:17px;
    }
}

@media (max-width :380px){
    /* .ConsultingImg{
        height: 250px;
        width: 250px;
    } */
    /* .ConsultingParaDiv{
        height: 1200px;
    } */
    .Consulting-mainSection-subDiv{
        height: auto;
    }

}