@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
.cards {
  max-width: 20rem !important;
  max-height: 35rem !important;
  padding: 10px;
  border-radius: 20px !important;
  
  width: 400px !important;
  height: 340px !important;
  box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.8);
  margin-top: 50px;
  /* background-color: #000; */
} 
.cards h3{
  color:rgb(221, 33, 33) ;
  text-align: center;
}
.card-text{
  text-align: center;
  color:black !important;
  padding-bottom: 20px !important;
}
.card .img {
  width: 150px;
  max-width: 100% !important;
  margin: auto;
}
.body1 {
  /* background:url("./Images/cool-background.png") no-repeat center; */
  background-size: cover; 
  color: black;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  font-family:  "Jost", sans-serif;
}
.pcard h3{
  color: black;
}
.main-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-height: 100% !important;
}
.card3{
display: flex;
align-items: center;
justify-content: center;
flex-wrap: wrap;

/* background-color: aqua; */
}
/* .heading {
  padding: 30px;
  max-width: 50% !important;
  max-height: 600px;
  padding: 30px;
} */
.para {
  width: 100% !important;
  margin-top: 30px;
  max-width: 100%;
  max-height: 700px;
  margin-bottom: 100px;
}
.para p {
  font-size: 10px;
  align-self: center;
  color: black;
  text-align: justify;
  
  margin-bottom: 100px;
  
  
}
.customer-tagLine{
  margin: auto !important;
}
/* .heading h3 {
  margin-right: 593px;
} */
.heading h1 {
  margin-right: 12px;
  color:#FD0000;
  font-weight: 900;
  font-size: 3rem;
}
.heading h1 span {
  margin-right: 110px;
}

.productDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: auto;
 
  flex-wrap: wrap;
}
.btn {
  margin: 50px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 40px;
  font-size: 25px;
}
/* Add these styles at the end of your existing CSS file */

/* Adjustments for mobile screens */
@media (max-width: 768px) {
  .body1 {
    padding: 20px; /* Add some padding for better mobile view */
  }
  .main-content,
  .productDetails {
    padding: 20px; /* Add some padding for better mobile view */
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .cards {
    max-width: 100% !important;
    height: 600px !important;
    text-align: justify !important;
    width: 300px !important;
  }
  .card3{
    height: 190px !important;
    width: 100%;
    text-align: justify;
    /* background-color: rebeccapurple; */
    /* margin-bottom: 150px; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
}

/* Additional styles for larger screens if needed */
@media (min-width: 1200px) {
  .productDetails {
    padding: 40px; /* Adjust padding for larger screens */
  }
  .pcard-text{
    height: fit-content;
  }
  
}

@media (max-width:550px){
  .card3{
    height: 300px !important;
    max-height: auto;
    width: 100%;
    text-align: justify;
    /* background-color: green !important; */
    /* margin-bottom: 150px; */
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
  }
  .cards {
    /* max-width: 23rem !important;
    max-height: 25rem; */
    padding: 10px;
    border-radius: 20px !important;
    
    display:flex;
    align-items: center;
    justify-content: space-around;
    width: 100% !important;
    height: 400px !important;
  
    /* background-color: #000; */
    margin-top: 60px;
  }
  .cards .img {
    width: 30px;
    margin: auto;
  }
  .productDetails{
   margin-top: 100px !important;
  }
  
}
