/* .search-bar {
  position: fixed;
  top: 300px;
  right: 80px;
  color: red;
  background-color: black;
} */

.search-bar {
position: fixed;
  bottom: 100px;
  right: 25px;
  width: 60px;
  height: 60px;
  color: white;
 
  background-color:  black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}
/* Rest of the CSS remains the same */


.search-bar .search {
  display: none;
}

.search-bar.open .search {
  display: block;
}

.search-bar .fa-magnifying-glass.hidden {
  display: none;
}




.search {
  display: none;
  position: relative;
  right: 70px;
  bottom: 50px;
}

.search.open {
  display: block;
}

.search-input {
  width: 240px; /* Adjust width as needed */
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 20px;
}

.fa-magnifying-glass.hidden {
  display: none;
  font-size: 30px;
}
@media screen and (max-width: 780px) {
      .search-bar{
            width: 40px;
            height: 40px;
            right: 10px;
            bottom: 60px;
      }
}