@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
   font-family:  "Jost", sans-serif;
}
.App{
  max-width: 100%;
  overflow: hidden !important;
  font-family:  "Jost", sans-serif;
}
li{
  color: black !important;
}

a.active{
  color: black !important;
  border-bottom: 2px solid black;
}
Link{
  color: black !important;
}
a{
  color: black !important;
}

.scroll{
  font-size: 60px;
  display: inline;
  position: absolute;
  right: 0px;

}


