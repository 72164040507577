* {
    margin: 0;
    padding: 0;
    font-family: Inter, sans-serif;
  }
  
  
  .OvermainSection {
    height: auto;
    width: 100%;
    max-width: 100% !important;
    margin-top: 30px;
     /* background: url("./Images/cool-background.png") ; 
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; */
  }
  
  .OveraboutMainDiv {
    height: auto;
    width: 100%;
    background-color:transparent;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 100px; */
    /* background-color: aqua; */
  }
  
  .OverimageDiv {
    height: 800px;
    width: 100%;
    max-width: 100%;
   
  }
  
  .Overimagee {
    height: 100%;
    width: 100%;
  }
  
  .OverinfoDiv {
    width: 100%;
    max-width: 100%;
    text-align: center;
    margin: 40px;
    padding-top: 40px;
  }
  
  .Overabouth1 {
    color: black;
    margin-bottom: 10px;
    font-size: 35px !important;
    font-weight: bold;
  }
  
  .OveraboutHead {
    font-size: 30px;
    font-weight: bold;
    color: #FD0000;
    margin-bottom: 20px;
    text-align: center;
   
  }
  .OverinfoDiv h2{
    font-size: 2rem;
    font-weight: bold;
    color: black;
    margin-bottom: 20px;
  }
  
  .Overaboutpara {
    color: black;
    line-height: 1.6;
    /* margin-bottom: 15px; */
    text-align: justify;
    font-size: 17px;
    text-align-last: center;
  }
  
  .OverVMmainDiv {
    max-height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    padding: 20px;
    /* background-color: blue; */
  }
  .OvervmH{
    font-size: 20px;
    color: #FD0000;
    font-weight: bold;
  }
  
  .OvervmSubDiv {
   max-width: 40%;
    max-height: 100% !important;
    padding: 20px;
    text-align: left;
    /* margin: 20px; */
    box-shadow: 30px 30px 60px  rgba(0,0,0,0.25);
  }
  
  .OvervmHead {
    color: black;
    font-size: 30px;
  
  }
  
  .OvervmInfoDiv {
    width: 100%;
    color: black;
    /* text-align: justify; */
  }
  
  .OvervmPara {
    line-height: 1.5;
    /* margin-bottom: 20px; */
    font-size: 17px;
  }
  
  .OverreachUSDiv {
    height: auto;
    width: 100%;
    background-color: rgb(42, 42, 66);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  
  .OverreachUsInfoDiv {
    height: auto;
    width: 100%;
    max-width: 900px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }
  
  .OverreachUsInfo {
    color: white;
    font-size: 20px;
    margin: 10px;
    padding: 30px;
  }
  
  .OverreachUsBtnDiv {
    height: auto;
    width: 100%;
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .Overbtn {
    background-color: orangered;
    color: white;
    height: 43px;
    width: 120px;
    border: none;
    font-size: 17px;
    border-radius: 5px;
  }
  
  .Overbtn:hover {
    background-color: darkorange;
  }
  
  
  @media only screen and (max-width: 768px) {
    .OverimageDiv {
      height: 400px;
      max-width: 100%;
    }
  
    .OverinfoDiv {
      max-width: 90%;
    }
  
    .OveraboutHead {
      font-size: 3rem;
    }
  
    .OvervmSubDiv {
      max-width: 100%;
      
    }
  
    .OvervmHead {
      font-size: 3rem;
    }
  
    .OvervmInfoDiv {
      max-width: 100%;
     
    }
  
    .OverreachUsInfoDiv {
      max-width: 100%;
    }
  
    .OverreachUsInfo {
      font-size: 18px;
    }
  
    .Overbtn {
      width: 100px;
      font-size: 15px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .Overaboutpara {
      font-size: 16px;
    }
  
    .OvervmPara {
      font-size: 16px;
    }
  
    .OverreachUsInfo {
      font-size: 16px;
    }
  }