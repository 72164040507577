.industries-we-serve-heading{
    margin-top: 100px;
    font-size: 35px;
    /* margin-bottom: 20px; */
    font-weight: bold;
  }
.industries-we-serve-boxMainDiv1 {
      height: 100%;
      max-width: 100%;
      margin-top: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap:20px;
      flex-wrap: wrap;
      flex-direction: row;
      /* margin-left: 60px;
      margin-right: 60px;
      margin: auto; */
      /* padding: 30px; */
       
      /* margin-top: 0; */
  }
  .industries-we-serve-boxMainDiv2{
      max-height: 100%;
      max-width: 100%;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      gap:10px;
      flex-wrap: wrap;
      flex-direction: row;
      /* margin-left: 60px;
      margin-right: 60px;
      margin: auto; */
      padding: 20px;
      
      /* margin-left: 310px; */
  }
  .industries-we-serve-boxMainDiv-subDiv {
      height: 270px;
      width: 270px;
      /* background: url("./Images/cool-background.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover; */
      border-top-left-radius: 50px;
      border-bottom-right-radius: 50px;
      border: 4px solid white;
      box-shadow: 0 0 20px red;
      overflow: hidden; 
      position: relative;
      transition: all 0.3s ease; 
      /* padding: 20px; */
  }
  .industries-we-serve-boxMainDiv-subDiv:hover{
      transform: scale(0.9);
  }

  .industries-we-serve-boxMainDiv-subDiv img {
      width: 100%;
      height: 100%;
      /* filter: grayscale(100%); */
      transition: filter 0.3s ease;  
  }


  .industries-we-serve-boxMainDiv-subDiv:hover img {
      filter: grayscale(0%); 
  }

  .image-name {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white; 
      font-size: 20px; 
      font-weight: bold; 
      text-align: center; 
  }

  .industries-we-serve-info-section-heading {
      color: #FD0000;
      font-size: 25px;
      /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
      margin-bottom: 0; 
      text-align: center;
      /* background-color: blue; */
  }
  .hrr {
      border: none;
      border-top: 2px solid #FD0000; 
      width: 100%; 
      margin: 10px auto; 
  }
  .industries-we-serve-info-section-description{
      font-size: 17px;
      text-align: justify;
      padding: 40px;
      /* font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif; */
  }
  .industries-we-serve-info-section{
      /* margin-left: 60px;
      margin-right: 60px; */
      /* margin: auto; */
      width: 100% !important;
      /* background-color: aqua; */
      margin-top: 70px;
  }

@media only screen and(max-width:1260px){
    
}