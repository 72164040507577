.footer-main{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      /* padding: 50px; */
      background: url("./Images/cool-background1.png") ; 
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: black;
      /* margin: auto; */
      overflow: hidden;
}
.log img{
 width: 80px;
}

.log{
      margin-bottom: 90px;
      
      /* background-color: red; */
}
.sub h6{
      font-size: 20px;
}
.sub i{
      font-size: 30px;
}
.sub span{
      font-size: 20px;
      padding: 10px;
}
.sub{
      margin-top: 100px;
}
li{
      list-style: none;
      padding: 5px;
      font-size: 17px;
      
}
.company1{
      /* padding: 20px; */
     /* margin-bottom: 30px; */
     /* background-color: blue; */
      /* text-align: center; */
     display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      /* padding: 20px; */
      margin-bottom: 15px;
      height: 300px;
      text-align: left;
}
.footer-logo{
      /* margin-bottom: 100px; */
      width: 30% !important;
      /* background-color: red; */
      align-items: center;
      text-align: center;
      display: flex;
      justify-content: center;

}
/* .LogoFooterDiv{
      width: 100px;

} */

.contact{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;
      padding: 20px;
      margin: 20px;
      /* background-color:rebeccapurple; */
      text-align: left;
}
.contact i{
      font-size: 20px;
      padding: 10px;
     
}
.nav-brand1{
      min-width: 30%;
      /* background-color: blue; */
      width: 220px;
}
.contact span{
      font-size: 17px;
}
.social{
      margin-top: 5px;
      /* background-color: red; */
      align-items: center;
      justify-content: space-around;
      text-align: center;
}