/*=============== GOOGLE FONTS ===============*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap");

/*=============== VARIABLES CSS ===============*/


/*========== Responsive typography ==========*/
@media screen and (min-width: 1024px) {
  :root {
    --normal-font-size: 1rem;
  }
}

/*=============== BASE ===============*/




ul {
  list-style: none;
  /* Color highlighting when pressed on mobile devices */
  /*-webkit-tap-highlight-color: transparent;*/
}

a {
  text-decoration: none;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.container-nav {
  max-width: 100%;
  /* margin-inline: 1.5rem; */
  padding-left: 20px;
  padding-right: 20px;
}

/*=============== HEADER ===============*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  
  max-width: 100%;
  background-color: white;
  box-shadow: 0 2px 16px hsla(220, 32%, 8%, .3);
  z-index: 99999;
}

/*=============== NAV ===============*/


.nav__logo, 
.nav__burger, 
.nav__close {
  color:black;
}

.nav__data {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}


/* .nav__logo {
  display: inline-flex;
  align-items: center;
  column-gap: .25rem;
  font-weight: var(--font-semi-bold);
  /* Color highlighting when pressed on mobile devices */
  /*-webkit-tap-highlight-color: transparent;*/


/* .nav__logo i {
  font-weight: initial;
  font-size: 1.25rem;
} */

.nav__toggle {
  position: relative;
 margin-left: 10%;
}



.nav__burger, 
.nav__close {
  position: absolute;
  width: max-content;
  height: max-content;
  inset: 0;
  margin: auto;
  font-size: 2rem;
  cursor: pointer;
  transition: opacity .1s, transform .4s;
}

.nav__close {
  opacity: 0;
}

/* Navigation for mobile devices */
@media screen and (max-width: 1200px) {
  .nav__menu {
    position: absolute;
    left: 0;
    top: 2.5rem;
    width: 100%;
    height: calc(100vh - 3.5rem);
    overflow: auto;
    pointer-events: none;
    opacity: 0;
    transition: top .4s, opacity .3s;
  }
  .nav__menu::-webkit-scrollbar {
    width: 0;
  }
 
  .nav__list {
    /* background-color: var(--black-color); */
    padding-top: 0.5rem;
    background-color: white;
  }
  .nav__toggle{
      position: relative;
      
  }
  #nav-toggle-2,#nav-toggle-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    float: right;
  }
}


.nav__link {
  
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color .3s;
}

.nav__link:hover {
  background-color: var(--black-color-light);

}

/* Show menu */
.show-menu {
  opacity: 1;
  top: 6rem;
  pointer-events: initial;
}


keyframes animation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.logo img {
  width: 70px;
  height: 80px;
  margin-left: 10px;
  margin-bottom: 13px;

}

.logo span {
  color: #C40B0B;
}

.logo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  min-width: 20%;
}
.logo p {
  font-size: 15px;
  line-height: 0px;
}
.nav__toggle{
      max-width: 100% !important;
      
}


/* Show icon */
.show-icon .nav__burger {
  opacity: 0;
  transform: rotate(90deg);
}
.show-icon .nav__close {
  opacity: 1;
  transform: rotate(90deg);
}

/*=============== DROPDOWN ===============*/
.dropdown__item {
  cursor: pointer;
}

.dropdown__arrow {
  font-size: 1.25rem;
  font-weight: initial;
  transition: transform .4s;
}

.dropdown__link, 
.dropdown__sublink {
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;
  color: black;
  background-color:white;
  display: flex;
  align-items: center;
  /* column-gap: .5rem; */
  font-weight: var(--font-semi-bold);
  transition: background-color .3s;
}
.dropdown__link{
  width: 200%;
}
.dropdown__link i, 
.dropdown__sublink i {
  font-size: 1.25rem;
  font-weight: initial;
}

.search{
  border-radius: 20px !important;
  padding: 5px 10px;
}


.dropdown__link:hover, 
.dropdown__sublink:hover {
  background-color: black;
  color: white !important;
}

.dropdown__menu, 
.dropdown__submenu {
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s ease-out;
}

/* Show dropdown menu & submenu */
.dropdown__item:hover .dropdown__menu, 
.dropdown__subitem:hover > .dropdown__submenu {
  max-height: 1000px;
  transition: max-height .4s ease-in;
}

/* Rotate dropdown icon */
.dropdown__item:hover .dropdown__arrow {
  transform: rotate(180deg);
}

/*=============== DROPDOWN SUBMENU ===============*/
.dropdown__add {
  margin-left: auto;
}

.dropdown__sublink {
  background-color: var(--black-color-lighten);
}

/*=============== BREAKPOINTS ===============*/
/* For small devices */
@media screen and (max-width: 340px) {
  .container-nav {
    margin-inline: 1rem;
  }

  .nav__link {
    padding-inline: 1rem;
  }
}

/* For large devices */
@media screen and (min-width: 1200px) {
  .container-nav {
    margin-inline: auto;
  }

  .nav {
    height: calc(var(--header-height) + 2rem);
    display: flex;
    justify-content: space-between;
  }
  .nav__toggle {
    display: none;
  }
  .nav__list {
    height: 100%;
    display: flex;
    column-gap: 3rem;
  }
  .nav__link {
    height: 100%;
    padding: 0;
    justify-content: initial;
    column-gap: .25rem;
  }
  .nav__link:hover {
    background-color: transparent;
  }

  .dropdown__item, 
  .dropdown__subitem {
    position: relative;
  }

  .dropdown__menu, 
  .dropdown__submenu {
    max-height: initial;
    overflow: initial;
    position: absolute;
    left: 0;
    top: 6rem;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s, top .3s;
  }

  .dropdown__link, 
  .dropdown__sublink {
    padding-inline: 1rem 3.5rem;
  }

  .dropdown__subitem .dropdown__link {
    padding-inline: 1rem;
  }

  .dropdown__submenu {
    position: absolute;
    left: 100%;
    top: .5rem;
  }

  /* Show dropdown menu */
  .dropdown__item:hover .dropdown__menu {
    opacity: 1;
    top: 5.5rem;
    pointer-events: initial;
    transition: top .3s;
  }

  /* Show dropdown submenu */
  .dropdown__subitem:hover > .dropdown__submenu {
    opacity: 1;
    top: 0;
    pointer-events: initial;
    transition: top .3s;
  }
}
