/* Chatbot button */
.chatbot-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color:  #C40B0B;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999;
}

.chatbot-button img {
  width: 40px;
  height: 40px;
}

/* Chatbot popup */
.chatbot-popup {
  position: fixed;
  bottom: 100px; /* Adjust as needed */
  right: 20px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: none;
  transition: all 0.3s ease;
  z-index: 998;
}

.chatbot-popup.open {
  display: block;
}

/* Chatbot header */
.chatbot-header {
  background-color:  #C40B0B;
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
}

/* Close button */
.close-btn {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  outline: none;
}

/* Chatbot body */
.chatbot-body {
  padding: 10px;
}

/* Chatbot messages */
.chatbot-messages {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Bot message */
.bot-message {
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  padding: 10px 15px;
  margin: 10px 0;
}

/* Chatbot footer */
.chatbot-footer {
  padding: 10px;
}

/* Question input */
.question-input {
  width: calc(100% - 20px);
  height: 100px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
}

/* Generate answer button */
.generate-answer-btn {
  width: calc(100% - 20px);
  background-color:  #C40B0B;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* .generate-answer-btn:hover {
  background-color: #357dbf;
} */

.message {
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
}

.user {
  background-color: #4a90e2;
  color: #fff;
  align-self: flex-end;
}

.bot {
  background-color: #ffa500;
  color: #fff;
  align-self: flex-start;
}

/* Chatbot messages container */
.chatbot-messages {
  max-height: 300px; /* Adjust as needed */
  overflow-y: auto;
}

/* Chatbot message */
.message {
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
}

.user {
  background-color:  #C40B0B;
  color: #fff;
  align-self: flex-end;
}

.bot {
  background-color: black;
  color: #fff;
  align-self: flex-start;
}

/* Ensure the chatbot popup is displayed correctly on smaller screens */
@media screen and (max-width: 768px) {
  .chatbot-popup {
    width: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0;
  }

}

/* Ensure the chatbot button is visible and accessible on smaller screens */
@media screen and (max-width: 576px) {
  .chatbot-button {
    bottom: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
  }
  
}

/* Ensure the chatbot messages container scrolls properly on smaller screens */
@media screen and (max-width: 576px) {
  .chatbot-messages {
    max-height: calc(100vh - 180px); /* Adjust as needed */
  }
}

/* Adjust the font size for better readability on smaller screens */
@media screen and (max-width: 576px) {
  .message {
    font-size: 14px;
  }
}

/* Ensure the chatbot input and button are responsive */
@media screen and (max-width: 576px) {
  .question-input {
    height: 80px;
  }

  .generate-answer-btn {
    padding: 8px;
  }
}

.resize-handle {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  background-color: #000;
  cursor: nwse-resize; /* Resize cursor */
}
