.card11 {
    border: 1px solid #ddd;
    border-radius: 50px;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.8);
    
    margin: 20px;
    padding: 30px;
    width: 400px;
    height: 350px;
    opacity: 0.5;
  }
  .card11:hover{
    opacity: 1;
  }
  .super-div{
    margin-top: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .card11 img{
    width: 100%;
    height: 90%;
  }
  
  
  
  .card11-content {
    padding: 5px;
  }

  .product-Heading{
    margin-top: 150px;
    color: red;
    font-weight: bold;
  }
  .product-cards{
    
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

  }

.sub-div{
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
      max-width: 100%;
      margin-top: 100px;
      margin-bottom: 100px;
      
      
}
.sub-para{
      
     border-radius: 20px;
      max-width: 60% !important;
      padding: 30px;
      box-shadow: 3px 3px 6px rgba(0, 0, 0,0.9);
}

.btn1{
      padding: 5px 8px;
      color:  white;
      background-color: black;
      border-radius: 10px;
}
.card12{
      max-width: 50% !important;
      border-radius: 30px;
      padding: 30px;
      box-shadow: 3px 3px 6px rgba(0, 0, 0,0.9);
}
.card12 img{
      width: 150px;
}