.mainIndustries,
.mainExpertise,
.mainSourceDiv,
.mainGotToseat,
.mainWhatWe {
  
  padding: 30px;
  margin: 30px;
}

.mainWhatWe {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.newRecruitment {
  height: 300px;
  width: 300px;
  
}

.mainCardDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.sourceToSeat1,
.sourceToSeat2,
.sourceToSeat3 {
 
  padding: 20px;
 
  
  width: calc(100% - 40px);
  max-width: 100%;
  font-size: 25px;
  margin: auto;
  margin-top: 20px;
}

.newRecruitment img{
  height: 300px;
  width: 300px;
}
.mainWhatWeDiv1 {
 
  padding: 20px;
  margin: 20px;
  width: calc(70% - 40px); 
  max-width: 900px;
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.9);
}

.mainWhatWeDiv1 h3 {
  margin-bottom: 10px;
}

.MainImg11 {
  max-width: 100%;
  margin: 10px;
  height: auto;
}
.mainList li{
font-size: 25px;

}
.mainPara1{
      font-size: 25px;
}
.mainWhatWeDiv1 li{
      font-size: 25px;
}

@media only screen and (max-width: 768px) {
  .mainIndustries,
  .mainExpertise,
  .mainSourceDiv,
  .mainGotToseat,
  .mainWhatWe {
    margin: 20px auto; 
    padding: 20px; 
    max-width: 90%; 
  }

  .sourceToSeat1,
  .sourceToSeat2,
  .sourceToSeat3 {
    width: calc(100% - 40px);
    max-width: 100%;
    margin: 10px auto;
  }

  .gotToSeatPara {
    max-width: calc(100% - 40px);
  }

  .mainWhatWeDiv1 {
    width: calc(100% - 40px);
  }
}